<script lang="ts">
    import { ClubPackage, ClubPackageFeature, Currency, MarketFeature } from '@/models';
    import { currencyHTML } from '@/utils/filters/currency';
    import ClubFeatureCostText from '@/components/club/club-feature-cost-text.vue';
    import Vue, { PropType } from 'vue';

    export default Vue.extend({
        name: "ClubPricingMatrixItem",
        components: {
            'club-feature-cost-text': ClubFeatureCostText,
        },
        props: {
            selectedPackage: {
                type: Object as PropType<ClubPackage>,
                required: true,
            },
            allMarketFeatures: {
                type: Array as PropType<MarketFeature[]>,
            },
            memberMatchaMarketFeature: {
                type: Object as PropType<MarketFeature>
            },
            courtBookingMarketFeature: {
                type: Object as PropType<MarketFeature>
            },
            courtBookingLumpOnlyFee: {
                type: String
            },
            courtBookingLumpOnlyCurrency: {
                type: Object as PropType<Currency>
            },
            billingCycle: {
                type: Object
            }
        },
        methods: {
            getPackageFeatures(currentPackage: ClubPackage): MarketFeature[] {
                const includedFeatures = [] as MarketFeature[];

                currentPackage.feature_set.forEach((clubPackageFeature: ClubPackageFeature) => {
                    const fullMarketFeature = this.allMarketFeatures.find(f => f.feature == Number(clubPackageFeature.id));
    
                    if(fullMarketFeature)
                        includedFeatures.push(fullMarketFeature);
                });

                return includedFeatures;
            },
            featureClass(currentPackage: ClubPackage, featureName: string): string {
                const currentFeature = currentPackage.feature_set.find(f => f.name == featureName);
                if(!currentFeature)
                    return "not-available";

                return "";
            },
            featureStatusImage(currentPackage: ClubPackage, featureName: string): string {
                const currentFeature = currentPackage.feature_set.find(f => f.name == featureName);
                if(currentFeature)
                    return require("@/assets/images/feature-available.svg");
                else
                    return require("@/assets/images/feature-not-available.svg");
            },
            getPriceHTML(amount: string, currencyObj: Currency, postfix: string): string {
    
                if(!(amount && currencyObj))
                    return "";
    
                const postfixHTML = postfix ? `<span class='postfix'>${postfix}</span>` : "";

                return `${currencyHTML(amount, currencyObj, true, false)}${postfixHTML}<span class="frequency">&nbsp;/ ${this.billingCycle.frequency}</span>`;
            }
        }

    })
</script>

<style lang="scss">

.club-pricing-matrix-item {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
    margin-bottom: 20px;

    .top-border {
        height: 11px;
        margin: 0 -16px;

        &.silver {
            background-color: #87A2AE;
        }

        &.gold {
            background-color: #dda852;
        }

        &.platinum {
            background-color: #000000;
        }

    }

    .details {
        padding: 25px 31px;
        text-align: center;

        @media(min-width: 992px) {
            .top {
                min-height: 230px;
            }
        }

        h3 {
            font-size: 40px!important;
            font-weight: bold!important;;
            line-height: 55px!important;;
            margin-top: 0;
            margin-bottom: 25px!important;
        }

        .price {
            line-height: 45px;
            vertical-align: top;
            font: normal normal normal 16px 'Open Sans';
            display: flex;
            justify-content: center;
            
            .currency {
                display: inline;
                align-self: flex-start;
            }

            .amount {
                font: normal normal bold 45px/45px 'Open Sans';
                align-self: flex-start;
            }

            .frequency {
                align-self: flex-end;
            }
            
            .postfix {
                align-self: flex-end;
            }

            &.note {
                font: italic normal 300 11px/14px 'Open Sans';
            }
        }

        .per-player {
            .price {
                font-size: 16px;
                line-height: 16px;

                .currency {
                    font-size: 13px;
                    line-height: 13px;
                }

                .amount {
                    font: normal normal bold 20px/20px 'Open Sans';
                }
            }
        }

        .plus {
            font-size: 40px;
            text-align: center;
            display: block;
            font-weight: bold;
        }

        .btn-primary {
            border-radius: 25px;    // Nb: maybe this is our new standard style, but we are yet to apply it across the site

            &.silver {
                background-color: #87A2AE;
                border-color: #87A2AE;
            }

            &.gold {
                background-color: #dda852;
                border-color: #dda852;
            }

            &.platinum {
                background-color: #000000;
                border-color: #000000;
                color: white;
        }
        }

        .club-pricing-matrix-item-list {
            margin: 53px 0 23px;
            font: normal normal normal 16px/22px Open Sans;
            padding: 0;

            li {
                list-style-type: none;
                padding: 23px 0;
                border-bottom: 1px solid rgba(59, 59, 59, 0.3);
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border-bottom: none;
                }

                &.not-available span {
                    opacity: 0.3;
                }
            }
        }

        .btn-lg {
            padding-left: 66px;
            padding-right: 66px;
            font: normal normal bold 16px/40px Montserrat;
            text-transform: uppercase;
            text-decoration: none;
            color: #fff;
            margin-bottom: 15px;
        }

        a {
            text-decoration: underline;
            color: #026823;
            font-weight: 600;
            display: inline-block;
        }
    }
}

</style>

<template>
    <div class="col-xs-12 col-md-4">
        <div class="club-pricing-matrix-item" :class="selectedPackage.name">
            <div class="top-border" :class="selectedPackage.name"></div>
            <div class="details">
                <div class="top">
                    <h3>{{ selectedPackage.desc }}</h3>

                    <club-feature-cost-text v-if="courtBookingMarketFeature || memberMatchaMarketFeature" 
                        :market-features="getPackageFeatures(selectedPackage)" 
                        :billingCycle="billingCycle"
                        :showPricing=true>
                    </club-feature-cost-text>
                    <span v-else>
                        <span class="price" v-html="getPriceHTML(courtBookingLumpOnlyFee, courtBookingLumpOnlyCurrency)"></span>
                        <br />Limited availability *
                    </span>
                </div>
                <ul class="club-pricing-matrix-item-list">
                    <li :class="featureClass(selectedPackage, 'communication')"><span>Member Management</span> <img :src="featureStatusImage(selectedPackage, 'communication')"></li>

                    <li :class="featureClass(selectedPackage, 'communication')"><span>Communications Platform</span> <img :src="featureStatusImage(selectedPackage, 'communication')"></li>
                    <li :class="featureClass(selectedPackage, 'courtbooking')"><span>Court booking</span> <img :src="featureStatusImage(selectedPackage, 'courtbooking')"></li>
                    <li :class="featureClass(selectedPackage, 'courtbooking')"><span>Membership Fee Collection</span> <img :src="featureStatusImage(selectedPackage, 'courtbooking')"></li>
                    <li :class="featureClass(selectedPackage, 'matcha')"><span>Matcha (Hit Organisation)</span> <img :src="featureStatusImage(selectedPackage, 'matcha')"></li>
                    <li :class="featureClass(selectedPackage, 'clinics')"><span>Clinics</span> <img :src="featureStatusImage(selectedPackage, 'clinics')"></li>
                    <li :class="featureClass(selectedPackage, 'pointofsale')"><span>Point of Sale</span> <img :src="featureStatusImage(selectedPackage, 'pointofsale')"></li>
                </ul>

                <router-link class="btn btn-lg btn-primary" :class="selectedPackage.name" to="/signup">Sign Up</router-link>
                <br>
                <router-link to="/feedback">Request a Demo</router-link>
            </div>
        </div>
    </div>
</template>
