<script>
	import TutorialVideo from '@/components/tutorial-video.vue';
    import { mapGetters } from 'vuex';
    import ClubPricing from '@/components/club/club-pricing.vue';

    export default {
        name: 'Str8SetsHome',
        head: {
			title: { inner: 'Str8 Sets Tennis', separator: '|', complement: 'Software for Clubs, Players, Competitions' },
            meta: [
				{ name: 'description', content: 'Software made for Tennis Club Managers, Tennis Players and Tennis Competition Organizers. Clubs can manage memberships, clinics, court bookings, communications, hit arrangement and more. Str8 Sets web and mobile apps work hand in hand to simplify and improve tennis for everyone. Learn more...', id: 'desc'},
                { property: 'og:type', content: 'website' },
				{ property: 'og:url', content: 'https://www.str8sets.com' },
                { property: 'og:title', content: 'Str8 Sets Tennis | Software for Clubs, Players, Competitions' },
                { property: 'og:description', content: 'Software made for Tennis Club Managers, Tennis Players and Tennis Competition Organizers. Clubs can manage memberships, clinics, court bookings, communications, hit arrangement and more. Str8 Sets web and mobile apps work hand in hand to simplify and improve tennis for everyone. Learn more...' },
                { property: 'og:image', content: 'https://www.str8sets.com/img/meta_str8sets_tennis_eco_1200x628.jpg' },   
				{ property: 'og:image:alt', content: 'Str8 Sets Tennis Logo and Screenshot' },            
                { name: 'twitter:site', content: 'str8sets' },
				{ name: 'twitter:creator', content: '@str8sets' },
				{ name: 'twitter:card', content: 'summary_large_image' },  
				{ name: 'twitter:url', content: 'https://www.str8sets.com' },
				{ name: 'twitter:title', content: 'Str8 Sets Tennis | Software for Clubs, Players, Competitions' },
                { name: 'twitter:description', content: 'Software made for Tennis Club Managers, Tennis Players and Tennis Competition Organizers. Clubs can manage memberships, clinics, court bookings, communications, hit arrangement and more. Str8 Sets web and mobile apps work hand in hand to simplify and improve tennis for everyone. Learn more...' },
				{ name: 'twitter:image', content: 'https://www.str8sets.com/img/meta_str8sets_tennis_eco_1200x628.jpg' },
				{ name: 'twitter:image:alt', content: 'Str8 Sets Tennis Logo and Screenshot' }
            ],
			link: [
      			{ rel: 'canonical', href: 'https://www.str8sets.com', id: 'canonical' }
			]
        },
        components: {
            'club-pricing': ClubPricing,
			'tutorial-video': TutorialVideo    
        },
        computed: {
            ...mapGetters([
                'isLoggedIn'
            ])
        }
    }
</script>

<template>
	<!-- App Home, not logged in (landing page) -->
	<div v-if="!isLoggedIn" id="landing-page-content-not-logged-in">
		<!-- Hero Section -->
		<section class="hero-area">
			<div class="container">
				<div class="row">
					<div class="col-md-6 text-center zoom-hover"> <img :src="'/img/iphone_home_243x440.jpg'" alt="Str8 Sets App Screenshot (iPhone, Home)" width="243" height="440" v-rjs="2"> </div>
					<div class="col-md-6">
						<div class="block">
							<h1 class="">Love Tennis?</h1>
							<p>Get Str8 Sets, the service that helps:</p>
								<p><b>Club managers</b> manage their club efficiently and provide a superior tennis experience to their members.</p>
								<p><b>Players</b> Who love the game, want to keep track of their performance and play against different people.</p>
								<p><b>Competition organizers</b> who want to streamline the competition and make it easier for players to participate while saving their time!</p>
						</div>
						<div class="spacer"></div>
						<div class="hero-area-app-store">
							<div class="image-left">
								<a href="https://play.google.com/store/apps/details?id=com.elevatemobile.Str8SetsTennis&amp;hl=en" target="_blank"> <img width="200px" height="auto" alt="Google Play Store" src="@/assets/images/google-play-store.svg" data-no-retina /></a>
							</div>
							<div class="image-right">
								<a href="https://itunes.apple.com/au/app/str8-sets-tennis/id838192982" target="_blank"> <img width="200px" height="auto" alt="Apple Store" src="@/assets/images/apple-app-store.svg" data-no-retina /></a>
							</div>
						</div>
					</div>
				</div>
				<div class="for-section-wide">
					<div class="for-section-image">
						<router-link to="#clubs"><img src="@/assets/images/club.svg" alt="Green Club Icon" data-no-retina></router-link>
						<div class="for-text"> For Clubs </div>
					</div>
					<div class="for-section-image">
						<router-link to="#tournaments"><img src="@/assets/images/comps.svg" alt="Green Competitions Icons" data-no-retina></router-link>
						<div class="for-text"> For Competitions </div>
					</div>
					<div class="for-section-image">
						<router-link to="#players"><img src="@/assets/images/player.svg" alt="Green Playuer Icon" data-no-retina></router-link>
						<div class="for-text"> For Players </div>
					</div>
				</div>
			</div>
		</section>
		<div class="parallax image-tennis-net height-150"></div>
		<section class="feature section">
			<div class="container">
				<div class="row">
					<div class="col-md-4">
						<div class="feature-box">
							<h4>For Clubs</h4>
							<p>Everything you need to administer your tennis club. From <b>membership fee collection, hit organisation, court bookings and more</b>...</p>
						</div>
						<div class="feature-box">
							<h4>For Competitions</h4>
							<p><b>Automatically created draws, emergency player management, live ladders</b>, Str8 Sets for Competitions is all you need.</p>
						</div>
						<div class="feature-box">
							<h4>For Players</h4>
							<p><b>Match diary, player social network, player performance</b> are some of the many features of Str8 Sets supporting players.</p>
						</div>
					</div>
					<div class="col-md-4 text-center feature-box-centre-image zoom-hover"> <img :src="'/img/iphone_courtbookings_243x440.jpg'" alt="Str8 Sets App Screenshot (iPhone, Court Bookings)" v-rjs="2"> </div>
					<div class="col-md-4">
						<div class="feature-box">
							<h4>Beautiful Apps</h4>
							<p>Str8 Sets is made for iOS and Android. Two beautiful apps allow you to <b>manage every aspect of your tennis activity</b>.</p>
						</div>
						<div class="feature-box">
							<h4>Complete Solution</h4>
							<p>We understand the demands of tennis players, clubs and competition organisers. <b>Str8 Sets is the complete tennis solution</b>.</p>
						</div>
						<div class="feature-box">
							<h4>Used Globally</h4>
							<p>Str8 Sets is <b>in use all around the world</b>. From the United States to Australia and everything in between we have you covered.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<div class="parallax image-tennis-net height-150" id="clubs"></div>
		<section class="feature-list section">
			<div class="container">
				<div class="row">
					<div class="col-md-12 heading"> <img class="icon" src="@/assets/images/club.svg" alt="Green Club Icon" data-no-retina>
						<h2>Str8 Sets <b>for Tennis Clubs</b></h2>
						<h4>Managing every aspect of your tennis club</h4> </div>
				</div>
				<div class="container"> 
					<tutorial-video
						:videoVimeoId=267005551
						title="Str8 Sets for Clubs Quick Intro"
						bodyText="Learn why Str8 Sets is perfect for your tennis club.">
					</tutorial-video>
				</div>
				<div class="spacer"></div>
				<div class="spacer"></div>
				<div class="spacer"></div>
				<div class="row">
					<div class="col-md-6">
						<div class="content mt-100">
							<p><b>Club administration made easy!</b></p>
							<p>We love tennis and want to see the sport flourish. We do that by offering solutions to clubs and players that make management and administration easier, allowing players to play more often and at a higher level.</p>
							<p>Club managers are the backbone of tennis. We know that a lot of tennis administration is done by people who are driven primarily by their passion and love for the sport. As a club manager, you would often feel time pressure to solve key problems for your club, sometimes at the cost of leaving out more interesting and innovative activities you want to do.</p>
							<p>Str8 Sets for Clubs provides you with a holistic, tennis-specific solution for a lot of the problems facing club managers and players today.</p>
							<p>Learn more about Str8 Sets for Clubs features below
								<router-link to="/case-studies_clubs">or see how clubs benefited from Str8 Sets club management tools.</router-link>
							</p>
						</div>
					</div>
					<div class="col-md-6 text-center"> <img class="img_monitor zoom-hover" :src="'/img/dashboard-court-bookings.png'" alt="Str8 Sets Dashboard Screenshot (Court Bookings)" v-rjs="2"> </div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12"> <img class="icon" src="@/assets/images/matcha.svg" alt="Green Matcha Icon" data-no-retina>
							<h2>Matcha for Clubs</h2> 
						</div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Organise and setup hits for members at your club based on skill level.</p>
							<p>As a Club administrator, you know that match arranging is a time-consuming activity and doesn't always work. Ideally, you want your members to have a quality hit whenever they want with as many people as possible.</p>
							<p>Using Matcha, ensure your members find the best hit for them at the time they want.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_find_a_hit_matcha" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12 indented">
							<h2>Clinics</h2> <img class="icon-right" src="@/assets/images/market-feature-icons/clinics.svg" alt="Green Clinics Icon" data-no-retina> 
						</div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Setup and manage clinics with ease directly via the dashboard.</p>
							<p>Participants (members or guests) will never miss another clinic as they can join via the app, paying for the clinic instantly. Str8 Sets Clinics is the perfect solution for clubs with a heavy focus towards coaching (or recreational) clinics.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_clinics" role="button">LEARN MORE</router-link>
					</div>
				</div>

				<div class="card">
					<div class="row">
						<div class="col-md-12">
							<h2>Court Bookings</h2> <img class="icon" src="@/assets/images/courts.svg" alt="Green Courts Icon" data-no-retina> 
						</div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Allow members (or non-members) to book a court at your club. If payment is required, it's completed via the app as the booking is made.</p>
							<p>Via the Club dashboard, the Club administrator has full control over every aspect of Court Bookings. Court Bookings is flexible, supporting a wide range of club-court configurations.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_court_bookings" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12 indented"> <img class="icon-right" src="@/assets/images/payments.svg" alt="Green Payments Icon" data-no-retina>
							<h2>Membership Fee Collection</h2> 
						</div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Don't lose members again, membership renewal made simple, fast and efficient.</p>
							<p>Members can renew their membership instantly (automatically or manually as set by club) via the Str8 Sets app. Never waste time chasing membership renewals again, spend your time harvesting new members for your club.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_club_member_fee_collection" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12">
							<h2>Member Communications</h2> <img class="icon" src="@/assets/images/messages.svg" alt="Green Messages Icon" data-no-retina> 
						</div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Communicate with your members, with ease and instantly!</p>
							<p>Give your club a voice! Member communications allows you to send messages or newsletters to your members, delivered instantly via the Str8 Sets iOS and Android app.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_club_member_communications" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12 indented">
							<h2>Point of Sale</h2> <img class="icon-right" src="@/assets/images/market-feature-icons/pointofsale.svg" alt="Green Point of Sale Icon" data-no-retina> 
						</div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Setup and manage your very own Club Store!</p>
							<p>Allow members and guests to make purchases of items at your club with our custom point of sale system. Members can pay via the app or provide you with an alternate payment method to process the sale instantly.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_club_point_of_sale" role="button">LEARN MORE</router-link>
					</div>
				</div>
			</div>
		</section>
		<div class="container">
			<div class="row">
				<div class="col-md-6 text-center"> <img class="img_monitor zoom-hover" :src="'/img/dashboard-insights.png'" alt="Str8 Sets Dashboard Screenshot (Insights)" v-rjs="2"> </div>
				<div class="col-md-6 text-center"> <img class="img_monitor zoom-hover" :src="'/img/dashboard-clinics.png'" alt="Str8 Sets Dashboard Screenshot (Clinics)" v-rjs="2"> </div>
			</div>
		</div>
		<div class="spacer-large"></div>
		<club-pricing></club-pricing>		
		<div class="parallax image-tennis-net height-150" id="tournaments"></div>
		<section class="feature-list section">
			<div class="container">
				<div class="row">
					<div class="col-md-12 heading"> <img class="icon" src="@/assets/images/comps.svg" alt="Green Competitions Icon" data-no-retina>
						<h2>Str8 Sets <b>for Competitions</b></h2>
						<h4>Manage competitions with ease</h4> </div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="content mt-100">
							<p>Do you run a league competition?</p>
							<p>Gone are the days of paper score-cards, waiting to submit scores and waiting for the ladder to update.</p>
							<p>Str8 Sets for Competitions gives you full control over your competition whilst allowing players to submit scores, view draws, organise fill-ins and generally enjoy the experience of competitive tennis.</p>
							<p>Setting up and configuring a competition is done via the website. With our emergency player management you'll never be left chasing fill-ins again!</p>
							<p>Learn more about these feature below
								<router-link to="/case-studies_competitions">or see how others benefited from Str8 Sets.</router-link>
							</p>
						</div>
					</div>
					<div class="col-md-6 text-center"> <img class="img_mobile_feature zoom-hover" :src="'/img/android_competitions_243x440.jpg'" alt="Str8 Sets App Screenshot (Android, Competitions)" v-rjs="2" /> </div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12"> <img class="icon" src="@/assets/images/comps.svg" alt="Green Competitions Icon" data-no-retina>
							<h2>Competition Management</h2> </div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Supporting a intra-club or inter-club formats, Str8 Sets Competitions provides you with all the tools to administer your league with ease.</p>
							<p>Automatically generate draws, allow players to enter (and verify) scores as they step off the court, real-time ladders and much more. <b>Str8 Sets offers a complete competition solution</b>.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_league_management" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12 indented">
							<h2>Emergency Player Management</h2> <img class="icon-right" src="@/assets/images/fillin.svg" alt="Green Fillin Icon" data-no-retina> </div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Don't ring around ever again!</p>
							<p><b>One of our most loved and in demand features</b> is competition fill-in requests. If a player can't play on a particular day, they can submit a fill-in request and it will be snapped up in moments by free players. </p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_league_management" role="button">LEARN MORE</router-link>
					</div>
				</div>
			</div>
		</section>
		<div class="parallax image-tennis-net height-150" id="players"></div>
		<section class="feature-list section">
			<div class="container">
				<div class="row">
					<div class="col-md-12 heading"> <img class="icon" src="@/assets/images/player.svg" alt="Green Player Icon" data-no-retina>
						<h2>Str8 Sets <b>for Tennis Players</b></h2>
						<h4>The must have app for tennis players that love to play!</h4> </div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="content mt-100">
							<p><b>Get more out of the game of tennis!</b></p>
							<p>Ever wanted a hit of tennis but don't have a partner? Using our skill-based ranking system Matcha you can request a hit when you want with players at your skill level. It's so easy!</p>
							<p>Download the app and start enjoying tennis more than you did before. Str8 Sets is a platform you can use to improve your game, record your match scores, communicate with other players, view your performance and more.</p>
							<p>Players from all around the world are using Str8 Sets and there's no reason you and your friends can't start using it today. Simply download the app, verify your email address and you're done!</p>
							<p>Learn more about these features below.</p>
						</div>
					</div>
					<div class="col-md-6 text-center"> <img class="img_mobile_feature zoom-hover" :src="'/img/iphone_matches_243x440.jpg'" alt="Str8 Sets App Screenshot (iPhone, Matches)" v-rjs="2"> </div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12"> <img class="icon" src="@/assets/images/matches.svg" alt="Green Matches Icon" data-no-retina>
							<h2>Match Diary</h2> </div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Record and view all your matches. From social hits, competition play to casual matches Str8 Sets provides you with a <b>central spot to store match results</b>.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_match_diary" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12 indented">
							<h2>Player Social Network</h2> <img class="icon-right" src="@/assets/images/player.svg" alt="Green Player Icon" data-no-retina> </div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>Link up with your friends and <b>meet other players in your local area</b>.</p>
							<p>Tennis is social, it's the perfect sport to meet new people and at the end of the day, tennis is fun! Str8 Sets allows you to create your own personal social network of players.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="tennis_circle" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12"> <img class="icon" src="@/assets/images/trophy-green-circle.svg" alt="Green Trophy Icon" data-no-retina>
							<h2>Player Performance</h2> </div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p><b>Ever wonder how you stack up against others?</b> In depth statistics allow you to improve all aspects of your game and to view/compare yourself to others.</p>
							<p>With Str8 Sets you can now view head-to-head, win/loss, skill ratings and more.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="/tennis_player_profile" role="button">LEARN MORE</router-link>
					</div>
				</div>
				<div class="card">
					<div class="row">
						<div class="col-md-12 indented">
							<h2>SlamFan</h2> <img class="icon-right" src="@/assets/images/slamfan_icon.svg" alt="SlamFan Icon" data-no-retina> </div>
					</div>
					<div class="row">
						<div class="col-md-8 indented">
							<p>SlamFan is a game you can participate to win real prizes, <b>build stronger connections</b> and most importantly- <b>have fun</b>!</p>
							<p>SlamFan tests your tennis knowledge and ability to pick winners. But it's also strategic as it forces you to pick the most likely winners in the end.</p>
						</div>
					</div>
					<div class="row bottom-row">
						<router-link class="green_button right" to="slamfan" role="button">LEARN MORE</router-link>
					</div>
				</div>
			</div>
		</section>
		<div class="parallax image-tennis-net">
			<section class="testimonials section">
				<div class="container">
					<div class="row">
						<div class="col-md-4 text-center">
							<div class="testimonial-block">
								<p> Str8 Sets has <strong>significantly streamlined operations in our club</strong>. Court bookings and membership fee collection have saved us time and made our members happy. </p>
								<div class="author-details"> <img data-no-retina src="@/assets/images/logo.svg" alt="Str8 Sets Logo">
									<h4>Frank</h4> <span> Hampton TC</span> </div>
							</div>
						</div>
						<div class="col-md-4 text-center">
							<div class="testimonial-block">
								<p> Str8 Sets allows us to manage our league club competition <strong>effortlessly and paperless</strong>. It saves us time on many fronts and the players love the real time and mobile experiences of the app! </p>
								<div class="author-details"> <img data-no-retina src="@/assets/images/logo.svg" alt="Str8 Sets Logo">
									<h4>Bill</h4> <span>Dendy Park TC</span> </div>
							</div>
						</div>
						<div class="col-md-4 text-center">
							<div class="testimonial-block">
								<p> The Matcha level setup is done thoughtfully and comprehensively. <strong>It's impressive!</strong> It's been fun to see my points increase when I win! </p>
								<div class="author-details"> <img data-no-retina src="@/assets/images/logo.svg" alt="Str8 Sets Logo">
									<h4>Chris</h4> <span>Keen Tennis Player</span> </div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<div id="social">
			<div class="site-container">
				<div class="heading-text">
					<h2 style="text-align: center;">Str8 Sets makes tennis better!</h2> </div>
				<div class="heading-secondary-text">
					<h3 style="text-align: center;">Be sure to tell your friends about Str8 Sets.</h3> </div>
				<div class="links">
					<a href="https://www.facebook.com/Str8SetsTennis" target="_blank"><img data-no-retina class="logo" alt="Str8 Sets Facebook" src="@/assets/images/facebook-logo.svg" /></a>
					<!--<a href="https://www.twitter.com/Str8Sets" target="_blank"><img data-no-retina class="logo" alt="Str8 Sets Twitter" src="@/assets/images/twitter_logo.svg" /></a>--></div>
				<div class="heading-secondary-text">
					<h3 style="text-align: center;">Str8 Sets will <b>improve the way you play tennis!</b></h3> </div>
			</div>
		</div>
	</div>
    
	<!-- App Home, logged in -->
	<div v-else></div>

</template>