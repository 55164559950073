<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        name: "BillingCycleSelection", 
        props: {
            billingCycles: {
                type: Array,
                default: () => [], 
            },
            selectedBillingCycle: {
                type: Object,
            }
        },
        methods: {
            selectBillingCycle(cycle) {
                this.$emit('handleSelect', cycle);
            }
        }
    })
</script>

<style lang="scss" scoped>
    .billing-cycle-selector {
        display: block;

        .billing-cycle {
            display: inline-block;
            height: 50px;
            padding: 0 33px;
            font-weight: bold;
            font-size: 18px;
            color: #026823;
            background-color: #fff;
            line-height: 46px;
            float: left;
            cursor: pointer;
            border-color: #026823;
            border-style: solid;
            border-width: 2px 0 2px 2px;

            &.selected {
                background-color: #026823;
                color: #fff;
            }

            &:first-child {
                border-radius: 25px 0 0 25px;
            }

            &:last-child {
                border-radius:  0 25px 25px 0;
                border-right-width: 2px;
            }
        }
    }

</style>

<template>
    <div class="billing-cycle-selector clearfix">
        <div v-for="cycle in billingCycles" :key="cycle.value" class="billing-cycle" v-on:click="selectBillingCycle(cycle)" :class="{'selected': (selectedBillingCycle.value == cycle.value)}">
            {{ cycle.text }}
        </div>
    </div>
</template>

