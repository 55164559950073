<script lang="ts">
    import { apiClient } from '@/utils/apiclient';
    import { Currency, ClubPackage, MarketFeature, Country } from '@/models';
    import { mapState } from 'vuex';
    import BillingCycleSelection from '@/components/billing-cycle-selection/billing-cycle-selection.vue';
    import ClubPricingMatrixItem from '@/components/club/club-pricing-matrix-item.vue';
    import Utilities from '@/utils/utilities';
    import Vue from "vue";

    export default Vue.extend({
        name: "ClubPricing",
        components: {
            'club-pricing-matrix-item': ClubPricingMatrixItem,
            'billing-cycle-selection': BillingCycleSelection,
        },
        data() {
            return {
                selectedCountry: "",
                allPackages: [] as ClubPackage[],
                allMarketFeatures: [] as MarketFeature[],
                communicationsMarketFeature: null! as MarketFeature,
                memberMatchaMarketFeature: null! as MarketFeature,
                pointOfSaleMarketFeature: null! as MarketFeature,
                clinicsMarketFeature: null! as MarketFeature,
                courtBookingMarketFeature: null! as MarketFeature,
                courtBookingLumpOnlyFee:"",
                courtBookingLumpOnlyCurrency: null! as Currency,
                courtBookingFootnote:"",
                billingCycles: Utilities.billingCycles,
                selectedBillingCycle: {},
            }
        },
        watch: {
            selectedCountry: function() {
                this.refreshPrices();
            }
        },
        computed: {
            ...mapState([
                'countries'
            ])
        },
        methods: {
            selectBillingCycle(cycle) {
                this.selectedBillingCycle = cycle;
            },
            refreshPrices() {
                const selectedCountryObj = this.countries.find((item: Country) =>  item.code == this.selectedCountry);

                apiClient.packages().then((packageData: ClubPackage[]) => {

                    this.allPackages= packageData;

                    apiClient.marketFeatures(this.selectedCountry).then(marketFeatures => {                        

                        this.allMarketFeatures = marketFeatures;

                        // reset
                        this.communicationsMarketFeature = null! as MarketFeature;
                        this.memberMatchaMarketFeature = null! as MarketFeature;
                        this.courtBookingMarketFeature = null! as MarketFeature;
                        this.courtBookingLumpOnlyFee = "";
                        this.courtBookingFootnote = "";
                        this.pointOfSaleMarketFeature = null! as MarketFeature;
                        this.clinicsMarketFeature = null! as MarketFeature;

                        // for each package, for each feature, if it is the membership or matcha one (which we need), get it's price
                        packageData.forEach((packageItem: ClubPackage) => {
                            
                            packageItem.feature_set.forEach(clubPackageFeature => {
                                
                                const marketFeature = marketFeatures.find((marketfeature: MarketFeature) => marketfeature.feature == Number(clubPackageFeature.id));

                                if(marketFeature && clubPackageFeature.name == "communication")
                                {
                                    this.communicationsMarketFeature = marketFeature;
                                }
                                else if(marketFeature && clubPackageFeature.name == "matcha")
                                {
                                    this.memberMatchaMarketFeature = marketFeature;
                                }
                                else if(marketFeature && clubPackageFeature.name == "pointofsale")
                                {
                                    this.pointOfSaleMarketFeature = marketFeature;
                                }
                                else if(marketFeature && clubPackageFeature.name == "clinics")
                                {
                                    this.clinicsMarketFeature = marketFeature;
                                }
                                else if(marketFeature && clubPackageFeature.name == "courtbooking")
                                {
                                     if(selectedCountryObj != null && selectedCountryObj.connect_available) {
                                         this.courtBookingMarketFeature = marketFeature;
                                         this.courtBookingFootnote = "* A payment may be a membership fee or a court booking cost if you charge for bookings.<br />^ Processing fee discounts are possible based on the number of members and cost of membership. Please enquire with us.";
                                    }
                                    else {
                                        this.courtBookingLumpOnlyFee = marketFeature.lump_sum_price;
                                        this.courtBookingLumpOnlyCurrency = marketFeature.currency_obj;
                                        this.courtBookingFootnote = "* In-app payments are not available in your country. Therefore the membership fee collection feature cannot be enabled. Also you will not be able to charge for court bookings on the app";
                                    }
                                }
                            });
                        }); 
                    })
                })
                // .catch(error => { 
                //     console.log(`An error occurred getting packages and pricing [${error}]`);
                // });
            }
        },
        mounted() {
            this.selectedCountry = "US";
            this.selectedBillingCycle = this.billingCycles[0];
        }
    })
</script>

<style lang="scss">

#main-clubpricing {
    position: relative;
    padding: 100px 0 125px;

    &::before {
        content: ' ';
        background-color: #f2f2f2;
        height: 100%;
        max-height: 659px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    @media(max-width: 991px) {
        padding-top: 30px;
    }

    h3 {
        font-size: 25px;
        line-height: 34px;
        font-weight: normal;
        margin-bottom: 46px;
    }

    .pricing-selections {
        @media(min-width: 992px) {
            padding-right: 100px;
        }

        label {
            font-weight: normal;
            font-size: 18px;
            margin-bottom: 10px;
        }

        .form-group {
            margin-bottom: 36px;
        }

        .form-control, select[multiple].form-control, select[size].form-control {
            border-radius: 25px;
            color: #026823;
            line-height: 50px;
            height: 50px;
            font-size: 18px;
            padding: 0 22px;
            appearance: none;
            background: url('~@/assets/images/arrow-down-green.svg') right 21px center no-repeat #fff;
            background-size: 24px;
        }

        .sale-flag {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            background-color: #cc156a;
            position: relative;
            height: 50px;
            display: inline-flex;
            align-items: center;
            padding-left: 17px;
            padding-right: 17px;
            margin-top: 37px;

            &::after {
                content: " ";
                position: absolute;
                right: -14px;
                top: 0;
                border-top: 25px solid transparent;
                border-right: none;
                border-left: 15px solid #cc156a;
                border-bottom: 25px solid transparent;
            }

            .subtext {
                font-size: 16px;
                margin-left: 0.75em;
            }

            @media(max-width: 1200px) {
                font-size: 19px;

                .subtext {
                    font-size: 14px;
                }
            }

        }
    }

    .footnote {
        font: normal normal normal 11px/15px Open Sans;
        color: #496B7B;
        padding: 20px 31px 40px;
    }
}
</style>

<template>
    <div id="main-clubpricing">
        <div class="container">
            <div class="row">
                <div class="pricing-selections col-xs-12 col-md-12">
                    <h2>Str8 Sets Plans</h2>
                    <h3>Choose the best set of features for your club.</h3>

                    <div class="col-xs-12 col-md-4 form-group">
                        <label for="country">Select your country:</label>&nbsp;&nbsp;
                        <select id="country" class="form-control" v-model="selectedCountry">
                            <option value="">--Select--</option>
                            <option v-for="country in countries" v-bind:key="country.code" v-bind:value="country.code">
                                {{ country.name }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="col-xs-12 col-md-4">
                        <div class="sale-flag pull-right">SAVE 17% <span class="subtext"> with yearly plan</span></div>
                    </div>

                    <div class="col-xs-12 col-md-4">
                        <div class="form-group">
                            <label for="country">Choose billing cycle:</label>
                            <billing-cycle-selection :billing-cycles="billingCycles" :selectedBillingCycle="selectedBillingCycle" @handleSelect="selectBillingCycle" ></billing-cycle-selection>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12">

                    <div v-if="!communicationsMarketFeature">
                        <p class="alert alert-warning">We are not quite ready to offer Str8 Sets in your country.<br/>Please contact us to register your interest.</p>
                    </div>
                    <div class="row" v-if="communicationsMarketFeature">
                        <club-pricing-matrix-item v-for="clubPackage in allPackages" 
                            :key="clubPackage.id" 
                            :selectedPackage="clubPackage"
                            :allMarketFeatures="allMarketFeatures"
                            :memberMatchaMarketFeature="memberMatchaMarketFeature"
                            :courtBookingMarketFeature="courtBookingMarketFeature"
                            :courtBookingLumpOnlyFee="courtBookingLumpOnlyFee"
                            :courtBookingLumpOnlyCurrency="courtBookingLumpOnlyCurrency"
                            :billingCycle="selectedBillingCycle">
                        </club-pricing-matrix-item>
                    </div>
                    <div class="row" v-if="communicationsMarketFeature">
                        <div class="col-xs-12">
                            <p class="footnote" v-html="courtBookingFootnote"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>            
</template>
