<script lang="ts">
    import { currency, currencyHTML } from '@/utils/filters/currency';
    import { Currency, MarketFeature } from '@/models';
    import Vue, { PropType } from 'vue';

    export default Vue.extend({
        name: "ClubFeatureCostText",
        props: {
            marketFeatures: {
                type: Array as PropType<MarketFeature[]>,
                default: () => [],
            },
            billingCycle: {
                type: Object,
                required: false,
                default: null
            },
            displayAsHTML: {
                type: Boolean,
                default: true
            },
            showPricing: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            hasLumpSum(): boolean {
                return this.totalLumpSum > 0;
            },
            hasTransactionFee(): boolean {
                return this.totalTransactionFee > 0;
            },
            hasPerPlayerPrice(): boolean {
                return this.totalPerPlayerPrice > 0;
            },
            totalLumpSum(): number {
                let lumpSumPrice = 0;
                this.marketFeatures.map(f => lumpSumPrice += (this.billingCycle && this.billingCycle.value == "M" ? f.lump_sum_price_monthly : f.lump_sum_price));

                return lumpSumPrice;   
            }, 
            totalTransactionFee(): number {
                let transactionFeePrice = 0;
                
                // just show the transaction fee for the first one that HAS a value
                const featureWithFee = this.marketFeatures.find(f => f.transaction_fee !== null);
                if(featureWithFee)
                    transactionFeePrice = featureWithFee.transaction_fee;

                return transactionFeePrice;   
            },
            totalPerPlayerPrice(): number {
                const multiplier = 1000;
                let perPlayerPrice = 0;
                this.marketFeatures.map(f => perPlayerPrice += (this.billingCycle && this.billingCycle.value == "M" ? f.per_player_price_monthly*multiplier : f.per_player_price*multiplier));
                return perPlayerPrice/multiplier;   
            },
        },
        methods: {
            getPriceHTML(amount: string, currencyObj: Currency, postfix: string): string {
                
                if(!(amount && currencyObj))
                    return "";
    
                const postfixHTML = postfix ? `<span class='postfix'>${postfix}</span>` : "";

                if(this.displayAsHTML) {
                    if(this.billingCycle)
                        return `${currencyHTML(amount, currencyObj, true, false)}${postfixHTML}<span class="frequency">&nbsp;/ ${this.billingCycle.frequency}</span>`;
                    else
                        return `${currencyHTML(amount, currencyObj, true, false)}${postfixHTML}`;
                }
                else {
                    if(this.billingCycle)
                        return `${currency(amount, currencyObj)}${postfixHTML}<span class="frequency">&nbsp;/ ${this.billingCycle.frequency}</span>`;
                    else
                        return `${currency(amount, currencyObj)}${postfixHTML}`;
                }
            }
        }
    });
    
</script>

<style lang="scss" scoped>

    div {
        display: inline;
    }

</style>

<template>
    
    <!-- A.Nosek 30/12/2021 Added a prop to control the display of prices (or not) -->
    <!-- Show prices, e.g. on the public page -->
    <div v-if="marketFeatures.length > 0 && showPricing">
        <span v-if="hasLumpSum" class="price" v-html="getPriceHTML(totalLumpSum, marketFeatures[0].currency_obj)"></span>

        <div v-if="hasPerPlayerPrice" class="per-player">
            <div v-if="hasLumpSum"><span class="plus">+</span></div>
            <span class="price" v-html="getPriceHTML(totalPerPlayerPrice, marketFeatures[0].currency_obj, '&nbsp;per member')"></span>
        </div>

        <div class="price note" v-if="hasTransactionFee">
            <br v-if="hasLumpSum || hasPerPlayerPrice">
            {{ totalTransactionFee }}% processing fee per payment&nbsp;*^
        </div>        
    </div>

    <!-- Hide prices, e.g. club onboarding -->
    <div v-else-if="marketFeatures.length > 0 && !showPricing">

        <div class="price note" v-if="hasTransactionFee">
            <strong>Cost:</strong> {{ totalTransactionFee }}% processing fee per payment.
        </div>        
    </div>

</template>
